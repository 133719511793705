import React, {memo, useEffect, useRef, useState, useCallback} from 'react';
import styled from 'styled-components';
import {IoSearchOutline} from 'react-icons/io5';
import theme from "../../styles/theme";
import {RegularText} from "./StyledText";
import {autoHyphen} from "../../utils/commons";

const Wrapper = styled.div`
  width: ${({$width}) => $width}px;
`;

const InputBox = styled.div`
  width: 100%;
  height: ${({$height}) => $height}px;
  font-family: 'AppleSDGothicNeoMedium';
  display: flex;
  align-items: center;
  position: relative;
  margin: ${({$margin}) => $margin};
  border: 1px solid ${theme.colors.lightGrayBorder};
  border-radius: 4px;
  background-color: ${theme.colors.whiteColor};
`;
const Title = styled(RegularText)`
  margin-bottom: 8px;
`;
const OptionBox = styled.div`
  width: ${({$width}) => $width}px;
  min-height: 50px;
  max-height: ${({$maxHeight}) => $maxHeight}px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  position: absolute;
  top: ${({$height}) => $height - 3}px;
  left: -1px;
  z-index: 10;
  overflow-y: scroll;
  border: 1px solid ${theme.colors.lightGrayBorder};
  border-radius: 4px;
  background-color: ${theme.colors.whiteColor};
`;

const OptionText = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 12px;
  color: ${({$fontColor}) => $fontColor ? $fontColor : theme.colors.blackColor};
  font-size: ${({$fontSize}) => $fontSize ? $fontSize : 14}px;
  font-weight: ${({$fontWeight}) => $fontWeight};
  word-break: keep-all;
  line-height: 1.2;
  cursor: pointer;

  &:hover {
    color: ${({$fontColor}) => $fontColor ? $fontColor : theme.colors.blackColor};
    background-color: ${theme.colors.whiteHoverColor};
  }
`;
const Input = styled.input`
  width: 100%;
  height: 100%;
  padding: 0 12px;
  border: ${({border}) => border};
  border-radius: 4px;
`;
const Asterisk = styled.span`
  color: ${theme.colors.activeRed};
`;

const StyledSelectInput = ({
                               ASTERISK,
                               PATIENT,
                               width = 440,
                               height = 50,
                               maxHeight = 400,
                               fontSize = 16,
                               margin,
                               title,
                               options = [],
                               name,
                               value = '',
                               onChange,
                               handleSelectOption,
                               maxLength = 100,
                               placeholder = '',
                           }) => {
    const boxRef = useRef(null);
    const optRef = useRef(null);
    const [optionVisible, setOptionVisible] = useState(false);

    const onClickOption = useCallback(option => {
        handleSelectOption(option);
        setOptionVisible(false);
    }, [handleSelectOption, setOptionVisible]);

    const onClickOutSide = useCallback(({target}) => {
        let boxRefCurrent = boxRef.current && boxRef?.current.contains(target);
        let optRefCurrent = optRef.current && optRef?.current.contains(target);

        if (!boxRefCurrent && !optRefCurrent) setOptionVisible(false);
    }, [boxRef, optRef]);

    useEffect(() => {
        if (options.length > 0) {
            setOptionVisible(true);
        }
    }, [options]);

    useEffect(() => {
        window.addEventListener('click', onClickOutSide);
        return () => window.removeEventListener('click', onClickOutSide);
    }, [onClickOutSide]);

    return (
        <Wrapper $width={width} $margin={margin}>
            {title && (
                <Title>
                    {title}
                    {ASTERISK && <Asterisk>*</Asterisk>}
                </Title>
            )}
            <InputBox
                $width={width}
                $height={height}
            >
                <Input
                    ref={boxRef}
                    $optionVisible={optionVisible}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onClick={() => setOptionVisible(!optionVisible)}
                    placeholder={placeholder}
                    maxLength={maxLength}
                />

                {optionVisible && options.length !== 0 && (
                    <OptionBox ref={optRef} $width={width} $height={height} $maxHeight={maxHeight}>
                        {options.map((option, index) => (
                            <OptionText key={`${index}-option`} $fontSize={fontSize}
                                        onClick={() => onClickOption(option)}>
                                {PATIENT && (
                                    `${option.pati_name} - ${autoHyphen(option.pati_cellphone)} - ${option.pati_rrn} - ${option.pati_chartNumber}`
                                )}
                            </OptionText>
                        ))}
                    </OptionBox>
                )}
            </InputBox>
        </Wrapper>
    );
};

export default memo(StyledSelectInput);
