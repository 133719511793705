import {toast} from "react-toastify";
import kakaoImg from "../../assets/images/kakaoImg.png";
import naverImg from "../../assets/images/naverImg.png";
import emrImg from "../../assets/images/emrImg.png";
import platformImg from "../../assets/images/logo.png";
import theme from "../../styles/theme";
import {isTime} from "../../utils/commons";

export const timeOption = () => {
    let timeArray = [];

    for (let i = 0; i < 48; i++) {
        let hours = Math.floor(i / 2);
        let minutes = i % 2 === 0 ? '00' : '30';

        hours = String(hours).padStart(2, '0');

        timeArray.push(`${hours}:${minutes}`);
    }

    return timeArray;
}
// export const timeOption = ['05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00'];
export const statusOption = ['예약 대기', '예약 완료', '내원 확정'];
// export const statusOption = ['예약 대기', '예약 완료', '내원 확정', '예약 취소'];
export const appointmentPlatformOption = ['전체', '네이버', '카카오', '플랫폼', 'EMR'];

export const filterChange = status => {
    switch (status) {
        // 공통
        case '전체':
            return 'total';
        // 상태
        case '예약 대기':
            return 'waiting';
        case '예약 완료':
            return 'complete';
        case '내원 확정':
            return 'confirm';
        case '예약 취소':
            return 'cancel';
        // 예약 매체
        case '네이버':
            return 'naver';
        case '카카오':
            return 'kakao';
        case '플랫폼':
            return 'platform';
        case 'EMR':
            return 'emr';

        // 한글 표기
        case 'naver':
            return '네이버';
        case 'kakao':
            return '카카오';
        case 'platform':
            return '플랫폼';
        case 'emr':
            return 'EMR';

        case 'waiting':
            return '예약 대기';
        case 'complete':
            return '예약 완료';
        case 'confirm':
            return '내원 확정';
        case 'cancel':
            return '예약 취소';

        default:
            return 'total';
    }
}

export const appointmentValidCheck = inputs => {
    const { name, phoneNumber, time, doctors } = inputs;
    let valid;

    if (name === '') {
        toast.error('이름을 입력해주세요.');
        valid = true;
    } else if (phoneNumber === '') {
        toast.error('휴대폰 번호를 입력해주세요.');
        valid = true;
    } else if (!isTime(time)) {
        toast.error('시간을 정확히 입력해주세요.');
        valid = true;
    } else if (doctors === '진료실 선택') {
        toast.error('진료실을 선택해주세요.');
        valid = true;
    }
    return valid;
}

export const getPlatformImg = platform => {
    let bgImg = platformImg;

    switch (platform) {
        case 'kakao':
            bgImg = kakaoImg;
            break;
        case 'naver':
            bgImg = naverImg;
            break;
        case 'emr':
            bgImg = emrImg;
            break;
        default:
            break;
    }

    return bgImg;
}

export const getStatusBgColor = status => {
    let bgColor = theme.colors.activeApricot;

    switch (status) {
        case 'waiting':
            bgColor = theme.colors.activeApricot;
            break;
        case 'confirm':
            bgColor = theme.colors.ultraLightGrayBorder;
            break;
        case 'complete':
            bgColor = theme.colors.whiteColor;
            break;
        default:
            break;
    }

    return bgColor;
}
