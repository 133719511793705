import React, {useCallback, useContext, useEffect, useLayoutEffect, useState} from "react";
import {toast} from "react-toastify";
import theme from "../../../styles/theme";
import FullPageModalBackground from "../../layout/FullPageModalBackground";
import {RegularText} from "../../styled/StyledText";
import StyledFlexBox from "../../styled/StyledFlexBox";
import StyledInput from "../../styled/StyledInput";
import StyledSelect from "../../styled/StyledSelect";
import StyledBorderCalendar from "../../styled/StyledBorderCalendar";
import {AppointmentContext} from "../../../pages/Appointment/appointment";
import {
    appointmentValidCheck,
    filterChange,
    statusOption,
    timeOption
} from "../../../pages/Appointment/appointment.lib";
import StyledCheckbox from "../../styled/StyledCheckbox";
import StyledTextarea from "../../styled/StyledTextarea";
import {useMutation, useQuery} from "@apollo/client";
import {
    SEARCH_PATIENT,
    SEE_MEDICAL_SUBJECT,
    SEE_MEDICAL_SUBJECT_DETAIL,
    SEE_RES_ALIM_TEMPLATE, SEE_RESERVATION_ALIM_SET
} from "../../../graphql/Common/query";
import {autoHyphen, errorMessage} from "../../../utils/commons";
import {CREATE_RESERVATION, UPDATE_RESERVATION} from "../../../graphql/Appointment/mutation";
import StyledRadio from "../../styled/StyledRadio";
import StyledAutoComplete from "../../styled/StyledAutoComplete";
import {InputSection, BlueTitle, NotificationTypeBox} from "./appointment.style";
import {SEE_RESERVATION_PATIENT_DETAIL} from "../../../graphql/Appointment/query";
import LoaderBackground from "../../share/LoaderBackground";
import Loader from "../../share/Loader";

const AppointmentModal = () => {
    const {
        selectedId,
        setSelectedId,
        doctorsOption,
        appointmentModal,
        setAppointmentModal,
        listRefetch,
        monthRefetch,
        weekRefetch,
        timeSelect,
        setTimeSelect,
        calendarSelectedDate
    } = useContext(AppointmentContext);
    const [inputs, setInputs] = useState({
        name: '',
        phoneNumber: '',
        memo: '',
        time: '',
        status: statusOption[0],
        doctors: '진료실 선택',
        largeCategory: '항목 선택',
        smallCategory: '항목 선택',
        smsTemplate: '저장된 문자 템플릿 선택하기',
        content: '',
    });
    const [date, setDate] = useState(new Date());
    const [checkbox, setCheckbox] = useState({
        notification: 'none',
        time1: false,
        time2: false,
        time3: false,
        time4: false
    });
    const [selectedUser, setSelectedUser] = useState(null); // 검색 후 선택한 user
    const [selectedMsId, setSelectedMsId] = useState(null); // 선택한 대분류 id
    const [selectedTemplate, setSelectedTemplate] = useState(''); // textarea template
    const [selectedTemplateId, setSelectedTemplateId] = useState(null); // 선택한 템플릿 id
    const [loading, setLoading] = useState(false);
    // Option
    const [largeCategoryOption, setLargeCategoryOption] = useState([]);
    const [smallCategoryOption, setSmallCategoryOption] = useState([]);
    const [templateOption, setTemplateOption] = useState([]);
    const [appointmentStatusOption, setAppointmentStatusOption] = useState(statusOption);

    // Query
    const {data: detailData, refetch: detailRefetch} = useQuery(SEE_RESERVATION_PATIENT_DETAIL, {
        variables: {
            reId: selectedId,
        },
        fetchPolicy: 'network-only'
    });
    const {data: largeCategoryData, loading: largeCategoryLoading} = useQuery(SEE_MEDICAL_SUBJECT);
    const {data: smallCategoryData, loading: smallCategoryLoading} = useQuery(SEE_MEDICAL_SUBJECT_DETAIL, {
        variables: {
            msId: selectedMsId,
        }
    });
    const {data: smsTemplateData, loading: smsTemplateLoading} = useQuery(SEE_RES_ALIM_TEMPLATE);
    const {data: patientData, loading: patientLoading} = useQuery(SEARCH_PATIENT, {
        variables: {
            searchTerm: inputs.name
        }
    });
    const {data: alimSetData, loading: alimSetLoading} = useQuery(SEE_RESERVATION_ALIM_SET);
    // Mutation
    const [createReservation] = useMutation(CREATE_RESERVATION);
    const [updateReservation] = useMutation(UPDATE_RESERVATION);

    // 검색 유저 선택
    const handleSelectOption = useCallback(value => {
        setSelectedUser(value);

        setInputs({
            ...inputs,
            name: `${value.pati_name} - ${autoHyphen(value.pati_cellphone)} - ${value.pati_rrn} - ${value.pati_chartNumber}`,
            phoneNumber: autoHyphen(value.pati_cellphone)
        });
    }, [inputs]);

    const onChange = useCallback(e => { // input, select onChange
        const {name, value} = e.target;

        if (name === 'phoneNumber') {
            setInputs({
                ...inputs,
                [name]: autoHyphen(value)
            });
        } else {
            setInputs({
                ...inputs,
                [name]: value
            });
        }
    }, [inputs]);

    const onChangeCheckbox = useCallback(e => { // checkbox onChange
        const {name, checked} = e.target;

        setCheckbox({
            ...checkbox,
            [name]: checked
        });
    }, [checkbox]);

    const onChangeRadio = useCallback(e => { // radio onChange
        const {name, value} = e.target;

        setCheckbox({
            ...checkbox,
            [name]: value
        });
        e.target.checked = false;
    }, [checkbox]);

    const handleUpdate = useCallback(async () => { // 예약자 수정
        if (appointmentValidCheck(inputs)) {
            return;
        }
        setLoading(true);
        try {
            const {data} = await updateReservation({
                variables: {
                    reId: selectedId,
                    resDate: date,
                    oneLineMemo: inputs.memo,
                    time: inputs.time,
                    status: filterChange(inputs.status),
                    doctorRoomName: inputs.doctors,
                    largeCategory: inputs.largeCategory,
                    smallCategory: inputs.smallCategory,
                    alimType: checkbox.notification,
                    alimTime1: checkbox.time1,
                    alimTime2: checkbox.time2,
                    alimTime3: checkbox.time3,
                    alimTime4: checkbox.time4,
                    alimTemplateId: selectedTemplateId,
                }
            });

            if (data.updateReservation) {
                await listRefetch();
                await monthRefetch();
                await weekRefetch();
                toast.info('예약자를 수정했습니다.');
                handleClose();
                setSelectedId(null);
                setLoading(false);
            }
        } catch (e) {
            errorMessage(e.message);
        } finally {
            setLoading(false);
        }
    }, [selectedId, inputs, date, checkbox, selectedTemplateId]);

    const handleSave = useCallback(async () => {
        if (appointmentValidCheck(inputs)) {
            return;
        }
        setLoading(true);
        try {
            const {data} = await createReservation({
                variables: {
                    patientName: inputs.name,
                    patientCellphone: inputs.phoneNumber,
                    resDate: date,
                    time: inputs.time,
                    status: filterChange(inputs.status),
                    doctorRoomName: inputs.doctors,
                    patientId: selectedUser?.pati_id ? selectedUser.pati_id : 0,
                    oneLineMemo: inputs.memo,
                    largeCategory: inputs.largeCategory === '항목 선택' ? '' : inputs.largeCategory,
                    smallCategory: inputs.smallCategory === '항목 선택' ? '' : inputs.smallCategory,
                    alimType: checkbox.notification,
                    alimTemplateId: selectedTemplateId,
                    alimTime1: checkbox.time1,
                    alimTime2: checkbox.time2,
                    alimTime3: checkbox.time3,
                    alimTime4: checkbox.time4,
                }
            });

            if (data.createReservation) {
                await listRefetch();
                await monthRefetch();
                await weekRefetch();
                toast.info('예약자를 등록했습니다.');
                handleClose();
                setLoading(false);
            }
        } catch (e) {
            errorMessage(e.message);
        } finally {
            setLoading(false);
        }
    }, [inputs, date, checkbox, selectedUser, selectedTemplateId]);

    const handleClose = useCallback(() => { // 모달 닫기
        setAppointmentModal(false);
        setInputs({
            name: '',
            phoneNumber: '',
            memo: '',
            time: '',
            status: statusOption[0],
            doctors: doctorsOption[0]?.dr_roomName,
            largeCategory: '항목 선택',
            smallCategory: '항목 선택',
            smsTemplate: '저장된 문자 템플릿 선택하기',
            content: '',
        });
        setDate(new Date());
        setSelectedUser(null);
        setSelectedMsId(null);
        setSelectedTemplate(null);
        setSelectedTemplateId(null);
        setTimeSelect(null);
        setSelectedId(null);
    }, [statusOption, doctorsOption]);

    useLayoutEffect(() => { // 각 상태 초기화
        if (!largeCategoryLoading && largeCategoryData) { // 대분류 데이터
            setLargeCategoryOption(largeCategoryData?.seeMedicalSubject?.medicalSubjectList?.map(item => item.ms_name));
        }

        if (inputs.largeCategory !== '항목 선택') { // 대분류 데이터 선택된 id 찾기
            const tmpId = largeCategoryData?.seeMedicalSubject?.medicalSubjectList?.find(item => item.ms_name === inputs.largeCategory)?.ms_id;
            setSelectedMsId(tmpId);
        }

        if (!smallCategoryLoading && smallCategoryData) { // 소분류 데이터
            setSmallCategoryOption(smallCategoryData?.seeMedicalSubjectDetail?.medicalSubjectDetailList?.map(item => item.msd_name));
        }

        if (!smsTemplateLoading && smsTemplateData) { // 문자 템플릿
            setTemplateOption(smsTemplateData?.seeResAlimTemplate?.templateList.map(item => item.rat_title));
        }

        if (inputs.smsTemplate !== '저장된 문자 템플린 선택하기') {
            const tmpTemplate = smsTemplateData?.seeResAlimTemplate?.templateList.find(item => item.rat_title === inputs.smsTemplate);

            if (tmpTemplate) {
                setSelectedTemplate(tmpTemplate?.rat_text);
                setSelectedTemplateId(tmpTemplate?.rat_id);
            }
        }
    }, [inputs, largeCategoryLoading, largeCategoryData, smallCategoryLoading, smallCategoryData, smsTemplateLoading, smsTemplateData]);

    useLayoutEffect(() => { // 기본 알림 설정 받아오기
        if (!alimSetLoading && alimSetData && appointmentModal) {
            // 발송 문자 선택 템플릿 text 값
            const tmpTemplate = smsTemplateData?.seeResAlimTemplate?.templateList.find(list => (list?.rat_id === alimSetData?.seeReservationAlimSet?.templateId))?.rat_title;

            if (tmpTemplate) {
                setInputs({
                    ...inputs,
                    smsTemplate: tmpTemplate,
                });
            }

            setCheckbox({
                notification: alimSetData?.seeReservationAlimSet?.type,
                time1: alimSetData?.seeReservationAlimSet?.time1,
                time2: alimSetData?.seeReservationAlimSet?.time2,
                time3: alimSetData?.seeReservationAlimSet?.time3,
                time4: alimSetData?.seeReservationAlimSet?.time4
            });

            setSelectedTemplateId(alimSetData?.seeReservationAlimSet?.templateId);
            setSelectedTemplate(alimSetData?.seeReservationAlimSet?.template);
        }
    }, [alimSetData, alimSetLoading, smsTemplateData, appointmentModal]);

    useLayoutEffect(() => {
        if (appointmentModal && detailData?.seeReservationPatientDetail?.reservationInfo) { // 선택한 리스트가 있을 때
            let data = detailData?.seeReservationPatientDetail?.reservationInfo;
            if (!data) return;

            // 선택된 발송 문자 템플릿
            const selectedTemplate = smsTemplateData?.seeResAlimTemplate?.templateList.find(list => list?.rat_text === data.template);

            setInputs({
                name: `${data.patientName || ''} - ${autoHyphen(data.patientCellphone) || ''} - ${data.patientRrn || ''} - ${data.patientChartNumber || ''}`,
                phoneNumber: autoHyphen(data.patientCellphone) || '',
                memo: data.oneLineMemo || '',
                time: data.time || '',
                status: filterChange(data.status),
                doctors: data.doctorRoomName || doctorsOption[0]?.dr_roomName,
                largeCategory: data.largeCategory || '항목 선택',
                smallCategory: data.smallCategory || '항목 선택',
                smsTemplate: smsTemplateData?.seeResAlimTemplate?.templateList.find(list => list?.rat_text === data.template)?.rat_title || '저장된 문자 템플릿 선택하기',
                content: data.template || ''
            });
            setDate(new Date(data.resDate));
            setCheckbox({
                notification: data.alimType || 'none',
                time1: data.alimTime1 || false,
                time2: data.alimTime2 || false,
                time3: data.alimTime3 || false,
                time4: data.alimTime4 || false
            });
            setSelectedTemplate(selectedTemplate.rat_title || '');
            setSelectedTemplateId(selectedTemplate?.rat_id);
            // setSelectedUser(null);
            // setSelectedMsId(null);


            switch (data?.status) { // 현재 예약 상태에 따라 선택할 수 있는 상태 필터링
                case 'waiting':
                    setAppointmentStatusOption(statusOption.filter(opt => opt !== '예약 대기'));
                    break;
                case 'complete':
                    setAppointmentStatusOption(statusOption.filter(opt => opt !== '예약 대기' && opt !== '예약 완료'));
                    break;
                case 'confirm':
                    setAppointmentStatusOption(['내원 확정']);
                    // setAppointmentStatusOption(statusOption.filter(opt => opt !== '예약 대기' && opt !== '예약 완료' && opt !== '내원 확정'));
                    break;
            }
        }
    }, [appointmentModal, detailData, smsTemplateData, statusOption, doctorsOption]);

    useLayoutEffect(() => {
        if (timeSelect) { // 예약 시간으로 추가하기
            setInputs({
                ...inputs,
                time: timeSelect
            });
            setDate(new Date(calendarSelectedDate));
        }
    }, [timeSelect, calendarSelectedDate]);

    useEffect(() => {
        (async () => {
            if (selectedId && appointmentModal) {
                await detailRefetch();
            }
        })();
    }, [selectedId, appointmentModal]);

    return (
        <FullPageModalBackground
            buttonTitle={`예약자 ${selectedId ? '수정' : '등록'}`}
            guideLine={`예약 > 예약자현황 > 예약자 ${selectedId ? '수정' : '등록'}`}
            modalTitle='예약자 정보 입력'
            visible={appointmentModal}
            onClose={handleClose}
            onClickButton={selectedId ? handleUpdate : handleSave}
            buttonColor={selectedId ? theme.colors.activeOrange : theme.colors.activeBlue}>
            {loading && (
                <LoaderBackground>
                    <Loader/>
                </LoaderBackground>
            )}
            <InputSection>
                <BlueTitle>예약자 정보 입력</BlueTitle>
                <StyledFlexBox margin='20px 0'>
                    <StyledAutoComplete
                        ASTERISK
                        PATIENT
                        title='이름'
                        name='name'
                        value={inputs.name}
                        onChange={onChange}
                        handleSelectOption={handleSelectOption}
                        options={inputs.name.length >= 2 ? patientData?.searchPatient?.patientList : []}
                        placeholder='예약자의 이름을 입력해주세요.'
                        disabled={!!selectedUser}
                    />
                    <StyledInput
                        ASTERISK
                        margin='0 0 0 30px'
                        title='휴대전화'
                        name='phoneNumber'
                        value={inputs.phoneNumber}
                        onChange={onChange}
                        placeholder='예약자 휴대폰 번호를 입력해주세요.'
                    />
                </StyledFlexBox>
                <StyledFlexBox>
                    <StyledInput
                        title='한줄 메모'
                        name='memo'
                        value={inputs.memo}
                        onChange={onChange}
                        placeholder='필요한 경우 한줄 메모를 입력해주세요.'
                    />
                </StyledFlexBox>
            </InputSection>
            <InputSection>
                <BlueTitle>예약자 상세 정보 입력</BlueTitle>
                <StyledFlexBox>
                    <StyledBorderCalendar
                        ASTERISK
                        width={440}
                        title='예약일'
                        value={date}
                        onChange={value => setDate(value)}
                    />
                    <StyledInput
                        ASTERISK
                        title="예약 일시"
                        margin="0 0 0 30px"
                        name="time"
                        value={inputs.time}
                        onChange={onChange}
                        maxLength={5}
                        placeholder="시간을 입력해주세요."
                    />
                    {/*<StyledSelect*/}
                    {/*    ASTERISK*/}
                    {/*    width={440}*/}
                    {/*    fontColor={inputs.time === '시간 선택' && theme.colors.fontGrayColor}*/}
                    {/*    margin='0 0 0 30px'*/}
                    {/*    title='예약일시'*/}
                    {/*    name='time'*/}
                    {/*    value={inputs.time}*/}
                    {/*    onChange={onChange}*/}
                    {/*    options={['시간 선택', ...timeOption()]}*/}
                    {/*/>*/}
                </StyledFlexBox>
                <StyledFlexBox margin='20px 0'>
                    <StyledSelect
                        ASTERISK
                        width={440}
                        title='예약상태'
                        name='status'
                        value={inputs.status}
                        onChange={onChange}
                        options={appointmentStatusOption}
                        disabled={detailData?.seeReservationPatientDetail?.reservationInfo?.status === 'confirm'}
                    />
                    <StyledSelect
                        ASTERISK
                        width={440}
                        fontColor={inputs.doctors === '진료실 선택' && theme.colors.fontGrayColor}
                        margin='0 0 0 30px'
                        title='진료실 정보'
                        name='doctors'
                        value={inputs.doctors}
                        onChange={onChange}
                        options={['진료실 선택', ...doctorsOption.map(room => room.dr_roomName)]}
                    />
                </StyledFlexBox>
                <StyledFlexBox>
                    <StyledSelect
                        width={440}
                        fontColor={inputs.largeCategory === '항목 선택' && theme.colors.fontGrayColor}
                        title='예약 구분(대분류)'
                        name='largeCategory'
                        value={inputs.largeCategory}
                        onChange={onChange}
                        options={['항목 선택', ...largeCategoryOption]}
                    />
                    <StyledSelect
                        width={440}
                        fontColor={inputs.smallCategory === '항목 선택' && theme.colors.fontGrayColor}
                        margin='0 0 0 30px'
                        title='예약 구분(소분류)'
                        name='smallCategory'
                        value={inputs.smallCategory}
                        onChange={onChange}
                        options={['항목 선택', ...smallCategoryOption]}
                    />
                </StyledFlexBox>
            </InputSection>
            <InputSection>
                <BlueTitle>알림 정보 입력</BlueTitle>
                <StyledFlexBox margin='0 0 30px'>
                    <NotificationTypeBox>
                        <RegularText>알림 발송 유형</RegularText>
                        <StyledFlexBox margin='20px 0 0'>
                            <StyledRadio
                                label='문자'
                                name='notification'
                                checked={checkbox.notification}
                                value='sms'
                                onChange={onChangeRadio}
                            />
                            <StyledRadio
                                label='카카오 알림톡'
                                margin='0 0 0 20px'
                                name='notification'
                                value='kakao'
                                checked={checkbox.notification}
                                onChange={onChangeRadio}
                            />
                        </StyledFlexBox>
                    </NotificationTypeBox>
                    <NotificationTypeBox>
                        <RegularText>알림 발송 시간 선택</RegularText>
                        <StyledFlexBox margin='20px 0 0'>
                            <StyledCheckbox
                                label='즉시'
                                id='time1'
                                name='time1'
                                checked={checkbox.time1}
                                onChange={onChangeCheckbox}
                            />
                            <StyledCheckbox
                                label='1일전'
                                margin='0 0 0 20px'
                                id='time2'
                                name='time2'
                                checked={checkbox.time2}
                                onChange={onChangeCheckbox}
                            />
                            <StyledCheckbox
                                label='2일전'
                                margin='0 0 0 20px'
                                id='time3'
                                name='time3'
                                checked={checkbox.time3}
                                onChange={onChangeCheckbox}
                            />
                            <StyledCheckbox
                                label='당일 오전 9시'
                                margin='0 0 0 20px'
                                id='time4'
                                name='time4'
                                checked={checkbox.time4}
                                onChange={onChangeCheckbox}
                            />
                        </StyledFlexBox>
                    </NotificationTypeBox>
                </StyledFlexBox>
                <StyledFlexBox alignItems='flex-start'>
                    <StyledSelect
                        width={440}
                        fontColor={inputs.smsTemplate === '저장된 문자 템플릿 선택하기' && theme.colors.fontGrayColor}
                        title='발송 문자 선택'
                        name='smsTemplate'
                        value={inputs.smsTemplate}
                        onChange={onChange}
                        options={['저장된 문자 템플릿 선택하기', ...templateOption]}
                    />
                    <StyledTextarea
                        height={166}
                        margin='0 0 0 30px'
                        title='발송 문자 미리보기'
                        // name='content'
                        // value={selectedTemplate}
                        // onChange={onChange}
                        placeholder={selectedTemplate || ''}
                        disabled
                    />
                </StyledFlexBox>
            </InputSection>
        </FullPageModalBackground>
    )
}

export default AppointmentModal;
