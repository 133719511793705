import React, {useCallback, useState} from "react";
import styled from "styled-components";
import ModalBackground from "../../layout/ModalBackground";
import theme from "../../../styles/theme";
import StyledInput from "../../styled/StyledInput";
import StyledFlexBox from "../../styled/StyledFlexBox";
import StyledButton from "../../styled/StyledButton";
import StyledFileUpload from "../../styled/StyledFileUpload";
import { toast } from "react-toastify";
import {createMonitorState, monitorTypeChange, resLocationChange} from "../../../pages/DID/did.lib";
import StyledSelect from "../../styled/StyledSelect";
import {useMutation} from "@apollo/client";
import {CREATE_DID_MONITOR} from "../../../graphql/DID/mutation";
import {RegularText} from "../../styled/StyledText";
import SortableFileList from "./SortableFileList";
import {useFileUpload} from "../../../hooks/useFileUpload";

const Contents = styled.div`
  display: flex;
  position: relative;
`;
const Section = styled.div`
  flex: 1;
`;
const Line = styled.div`
  position: relative;
  margin: 0 40px;

  &:after {
    content: '';
    width: 1px;
    height: calc(100% + 160px);
    top: -94px;
    position: absolute;
    background-color: ${theme.colors.ultraLightGrayBorder};
  };
`;
const BlueText = styled(RegularText)`
  font-size: 12px;
  color: ${theme.colors.activeBlue};
  margin: 20px 0;
`;

const MonitorModal = ({monitorModal, setMonitorModal, refetch}) => {
    const [options, setOptions] = useState(createMonitorState);
    const { fileList, setFileList, previewList, setPreviewList, onChangeFile } = useFileUpload();

    const [createDIDMonitor] = useMutation(CREATE_DID_MONITOR);

    // input, select onChange
    const onChange = useCallback(e => {
        const { name, value } = e.target;

        if (name === 'mediaType') { // 영상, 이미지 선택 시 파일 비우기
            setFileList([]);
            setPreviewList([]);
        }

        setOptions({
            ...options,
            [name]: value
        });
    }, [options]);

    // const handleDeleteFile = useCallback(name => {
    //     setFileList(prevFile => prevFile.filter(file => file.name !== name));
    // }, [fileList]);

    const handleClose = useCallback(() => {
        setOptions(createMonitorState);
        setFileList([]);
        setPreviewList([]);
        setMonitorModal(false);
    }, []);

    const handleSave = useCallback(async () => {
        if (options.name === '') {
            toast.error('모니터 제목을 입력해주세요.');
            return;
        }

        try {
            const { data } = await createDIDMonitor({
                variables: {
                    didTitle: options.name,
                    didMonitorType: monitorTypeChange(options.monitorType),
                    didMediaType: options.mediaType === '영상' ? 'video' : 'image',
                    didResUsed: options.usingAppointmentInfo === '사용',
                    didTransmitType: options.transmissionMethod === '상시 노출' ? 'always' : 'sometimes',
                    didResInfoLocation: resLocationChange(options.appointmentInfoLocation),
                    didMonitorRatio: options.screenRatio,
                    didAttached: fileList
                }
            });

            if (data.createDidMonitor) {
                toast.info('모니터를 추가했습니다.');
                handleClose();
                await refetch();
            }
        } catch (e) {
            toast.error(e.message);
        }
    }, [options, fileList]);

    return (
        <ModalBackground
            CONFIRM
            title='모니터 추가하기'
            width={1040}
            height={720}
            visible={monitorModal}
            onClose={handleClose}>
            <Contents>
                <Section>
                    <StyledInput
                        ASTERISK
                        title='모니터 제목'
                        name='name'
                        value={options.name}
                        onChange={onChange}
                        placeholder='모니터 제목을 입력해주세요. 예) 로비 메인 모니터'
                    />
                    <StyledFlexBox margin='20px 0' justifyContent='space-between'>
                        <StyledSelect
                            title='모니터 종류'
                            options={['가로모니터', '세로모니터']}
                            name='monitorType'
                            value={options.monitorType}
                            onChange={onChange}
                        />
                        <StyledSelect
                            title='정보 분류'
                            options={['영상', '이미지']}
                            name='mediaType'
                            value={options.mediaType}
                            onChange={onChange}
                        />
                    </StyledFlexBox>
                    <StyledFlexBox justifyContent='space-between'>
                        <StyledSelect
                            title='예약 정보 사용 여/부'
                            options={['사용', '미사용']}
                            name='usingAppointmentInfo'
                            value={options.usingAppointmentInfo}
                            onChange={onChange}
                        />
                        <StyledSelect
                            title='예약 정보 송출 방식'
                            options={['상시 노출', '일시 노출']}
                            name='transmissionMethod'
                            value={options.transmissionMethod}
                            onChange={onChange}
                        />
                    </StyledFlexBox>
                    <StyledFlexBox margin='20px 0' justifyContent='space-between'>
                        <StyledSelect
                            title='예약 정보 위치'
                            options={['위', '아래', '좌', '우']}
                            name='appointmentInfoLocation'
                            value={options.appointmentInfoLocation}
                            onChange={onChange}
                        />
                        <StyledSelect
                            title='화면 분할 (위:아래 / 좌:우)'
                            options={['1 : 1', '1 : 2', '1 : 3', '2 : 1', '3 : 1']}
                            name='screenRatio'
                            value={options.screenRatio}
                            onChange={onChange}
                        />
                    </StyledFlexBox>
                    {/*<StyledFlexBox justifyContent='space-between'>*/}
                    {/*    <StyledSelect*/}
                    {/*        title='광고 사용 여/부'*/}
                    {/*        options={['사용', '미사용']}*/}
                    {/*        name='usingAd'*/}
                    {/*        value={options.usingAd}*/}
                    {/*        onChange={onChange}*/}
                    {/*    />*/}
                    {/*</StyledFlexBox>*/}

                    <StyledButton
                        title='작성하기'
                        margin='60px 0 0'
                        onClick={handleSave}
                    />
                </Section>
                <Line/>
                <Section>
                    <StyledFileUpload
                        accept={options.mediaType === '영상' ? 'video/*' : 'image/*'}
                        onChange={onChangeFile}
                    />
                    <BlueText>순서대로 재생됩니다.</BlueText>
                    <SortableFileList
                        type={options.mediaType}
                        fileList={fileList}
                        setFileList={setFileList}
                        previewList={previewList}
                        setPreviewList={setPreviewList}
                    />
                </Section>
            </Contents>
        </ModalBackground>
    )
}

export default React.memo(MonitorModal);
