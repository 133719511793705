import React, {useCallback, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {IoArrowBack} from "react-icons/io5";
import * as F from '../findInfo.style';
import LogoutLogo from "../../../components/share/LogoutLogo";
import StyledInput from "../../../components/styled/StyledInput";
import StyledButton from "../../../components/styled/StyledButton";
import theme from "../../../styles/theme";
import {toast} from "react-toastify";
import {useMutation} from "@apollo/client";
import {FIND_USER_ACCOUNT_ID} from "../../../graphql/Login/mutation";

const FindEmail = () => {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({
        hospitalName: '',
        phoneNumber: '',
    });
    const [emailList, setEmailList] = useState([]);

    const [findUserAccountId] = useMutation(FIND_USER_ACCOUNT_ID);

    const onChange = useCallback(e => {
        const {name, value} = e.target;

        setInputs({
            ...inputs,
            [name]: value
        });
    }, [inputs]);

    const handleSearch = useCallback(async () => {
        if (inputs.hospitalName === '') {
            toast.error('병원명을 입력해주세요.');
            return;
        }

        try {
            const {data} = await findUserAccountId({
                variables: {
                    hospitalName: inputs.hospitalName,
                    cellphone: inputs.phoneNumber
                }
            });

            if (data?.findUserAccountId) {
                setEmailList(data?.findUserAccountId);
                setInputs({
                    hospitalName: '',
                    phoneNumber: '',
                });
            }
        } catch (e) {
            switch (e.message) {
                case 'err_00':
                    toast.error('아이디 찾기에 실패했습니다.');
                    break;
                case 'err_01':
                    toast.error('가입되지 않았거나 탈퇴된 정보입니다.');
                    break;
            }
        }
    }, [inputs]);

    return (
        <F.Wrapper>
            <LogoutLogo/>
            <F.FindBox>
                <F.GuideLine>로그인 > 아이디 찾기</F.GuideLine>
                <F.Title>
                    <F.GoBack onClick={() => navigate('/login')}>
                        <IoArrowBack/>
                    </F.GoBack>
                    아이디 찾기
                </F.Title>
                <F.BlueText>정보 입력</F.BlueText>

                <StyledInput
                    ASTERISK
                    title="병원명"
                    name="hospitalName"
                    value={inputs.hospitalName}
                    onChange={onChange}
                    onKeyPress={async e => e.key === 'Enter' && handleSearch()}
                    placeholder="병원명을 입력해주세요."
                />
                <StyledInput
                    margin="20px 0 40px"
                    title="휴대전화"
                    name="phoneNumber"
                    value={inputs.phoneNumber}
                    onChange={onChange}
                    onKeyPress={async e => e.key === 'Enter' && handleSearch()}
                    placeholder="전화번호를 입력해주세요."
                />
                <StyledButton
                    title="조회하기"
                    width={440}
                    borderRadius={4}
                    bgColor={theme.colors.activeIndigo}
                    onClick={handleSearch}
                />
                <F.Line/>
                <F.BlueText>
                    {emailList.length > 0 ? '조회된 ID' : '조회된 정보가 없습니다.'}
                </F.BlueText>
                <F.EmailList>
                    {emailList.map((email, index) => (
                        <F.EmailItem key={`${index}-${email}-email-list`}>
                            {email}
                        </F.EmailItem>
                    ))}
                </F.EmailList>

                <StyledButton
                    title="로그인하러 가기"
                    width={440}
                    fontColor={theme.colors.blackColor}
                    border={`1px solid ${theme.colors.lightGrayBorder}`}
                    borderRadius={4}
                    bgColor={theme.colors.whiteColor}
                    onClick={() => navigate('/login')}
                />
            </F.FindBox>
        </F.Wrapper>
    );
}

export default FindEmail;
