import React, {useCallback, useEffect, useRef} from 'react';
import styled, {css} from 'styled-components';
import {darken} from 'polished';
import {IoClose, IoHeartSharp, IoHeartOutline} from 'react-icons/io5';
import theme from '../../styles/theme';
import {BoldText} from '../styled/StyledText';
import {handleBodyScroll} from "../../utils/commons";
import HoverMenu from "../share/HoverMenu";

const Wrapper = styled.div`
  width: calc(100vw - 10px);
  height: 100vh;
  left: 0;
  top: 0;
  position: absolute;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: .25s;
  overflow: hidden;

  ${({$visible}) => $visible && css`
    opacity: 1;
    visibility: visible;
    z-index: 10;
  `};
`;
const ModalContent = styled.div`
  width: ${({$width}) => $width}px;
  height: 100vh;
  position: absolute;
  right: -100%;
  transition: .25s;
  background-color: ${theme.colors.whiteColor};

  ${({$visible}) => $visible && css`
    right: 0;
  `};
`;
const ModalHeader = styled.div`
  padding: 40px 40px 26px;
  display: flex;
  justify-content: space-between;
`;
const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const IconButton = styled.button`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  border-radius: 8px;

  &:hover {
    background-color: ${theme.colors.whiteHoverColor};
  }

  &:active {
    background-color: ${darken(0.1, theme.colors.whiteHoverColor)};
  }
`;

const RightSlideModal = ({
                             LIKE,
                             MENU,
                             visible,
                             width = 520,
                             title,
                             onClose = () => null,
                             isLiked = false,
                             onClickLike = () => null,
                             handleModifyComment = () => null,
                             handleDeleteComment = () => null,
                             children,
                         }) => {
    const bgRef = useRef(null);
    const modalRef = useRef(null);

    // 모달 밖 클릭 시 닫기
    const handleClickOutside = useCallback(e => {
        if (
            visible &&
            bgRef?.current?.contains(e.target) &&
            !modalRef?.current?.contains(e.target)
        ) {
            onClose();
            handleBodyScroll('initial');
        }
    }, [visible, bgRef, modalRef]);

    const handleClose = useCallback(() => {
        onClose();
        handleBodyScroll('initial');
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => document.removeEventListener('click', handleClickOutside);
    }, [handleClickOutside]);

    return (
        <Wrapper
            ref={bgRef}
            $visible={visible}>
            <ModalContent
                ref={modalRef}
                $width={width}
                $visible={visible}>
                <ModalHeader>
                    <BoldText>{title}</BoldText>
                    <ButtonBox>
                        {LIKE && (
                            <IconButton onClick={onClickLike}>
                                {isLiked
                                    ? <IoHeartSharp fontSize={16}/>
                                    : <IoHeartOutline fontSize={16} />
                                }
                            </IconButton>
                        )}
                        {MENU && (
                            <HoverMenu
                                NO_DELETE
                                margin='0 0 0 6px'
                                border={`1px solid ${theme.colors.ultraLightGrayBorder}`}
                                handleModifyComment={handleModifyComment}
                                handleDeleteComment={handleDeleteComment}
                            />
                        )}
                        <IconButton onClick={handleClose}>
                            <IoClose fontSize={18}/>
                        </IconButton>
                    </ButtonBox>
                </ModalHeader>
                {children}
            </ModalContent>
        </Wrapper>
    )
}

export default RightSlideModal;
