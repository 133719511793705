import React, {useLayoutEffect, useState} from 'react';
import styled from 'styled-components';
import {useQuery} from '@apollo/client';
import theme from '../../styles/theme';
import {SEE_BY_DAY_SCHEDULE} from '../../graphql/Home/query';
import {getHourMinute, getTenMinutesLater, scheduleBgColor} from '../../utils/commons';
import dayjs from "dayjs";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
const TableHeader = styled.thead`
  width: 100%;
`;
const TableHead = styled.th`
  vertical-align: middle;
  width: calc((1920px - 394px) / 24);
  height: 40px;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};

  &:first-child {
    width: 238px !important;
    text-align: left;
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }
`;
const TableBody = styled.tbody``;
const TableRow = styled.tr`
  position: relative;
`;
const TableData = styled.td`
  height: 86px;
  vertical-align: middle;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }
`;
const TimeBox = styled.div`
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
`;
const MinuteBox = styled.div`
  flex: 1;
  height: 100%;
  position: relative;
  background-color: ${({$bgColor}) => $bgColor};
`;
const TimeTypeText = styled.span`
  font-family: 'AppleSDGothicNeoRegular';
  padding-left: 12px;
  position: absolute;
  z-index: ${({$zIndex}) => $zIndex ? $zIndex : 1};
  left: 0;
  top: 48%;
  transform: translateY(-50%);
  background-color: inherit;
`;
const NoScheduleText = styled.div`
  height: 100%;
  font-size: 24px;
  font-family: 'AppleSDGothicNeoBold';
  color: ${theme.colors.activeIndigo};
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  transform: translateY(-13%);
`;

const OperationDaySchedule = ({
                                  date,
                                  searchTerm = '',
                                  sortType = 'desc',
                              }) => {
    const [doctorsDaySchedule, setDoctorsDaySchedule] = useState([]);

    const {data, loading} = useQuery(SEE_BY_DAY_SCHEDULE, {
        variables: {
            searchDate: new Date(date),
            searchTerm: searchTerm,
            orderBy: sortType
        },
        fetchPolicy: 'network-only'
    });

    useLayoutEffect(() => {
        // console.info('스케줄 ::: ',data?.seeByDaySchedule);
        if (!loading && data) {
            const scheduleList = data?.seeByDaySchedule.map(list => {
                let tmpSchedule = [];
                let doctorsInfo = {doctorsName: list.title}

                for (let hour = 0; hour < 24; hour++) { // 25 시간
                    let minutes = [];

                    for (let minute = 1; minute <= 6; minute++) { // 10분 단위 60분
                        let cellTime = getHourMinute(hour, minute * 10);
                        let startTime = getHourMinute(list.startHour, list.startMin);
                        let endTime = getHourMinute(list.endHour, list.endMin);
                        let lunchStartTime = getHourMinute(list.lbStartHour, list.lbStartMin);
                        let lunchEndTime = getHourMinute(list.lbEndHour, list.lbEndMin);
                        // let lunchStartTime = (getHourMinute(list.lbStartHour, list.lbStartMin) === '00:00') ? '12:00' : getHourMinute(list.lbStartHour, list.lbStartMin);
                        // let lunchEndTime = getHourMinute(list.lbEndHour, list.lbEndMin) === '00:00' ? '13:00' : getHourMinute(list.lbEndHour, list.lbEndMin);
                        let pmStartTime = getHourMinute(list.lbEndHour, list.lbEndMin);
                        // let pmStartTime = (getHourMinute(list.lbEndHour, list.lbEndMin) === '00:00' ? '13:00' : getHourMinute(list.lbEndHour, list.lbEndMin));
                        let breakStartTime = getHourMinute(list.offStartHour, list.offStartMin);
                        let breakEndTime = getHourMinute(list.offEndHour, list.offEndMin);

                        if (list.startHour === 0 && list.startMin === 0 && list.endHour === 0 && list.endMin === 0) { // 스케줄이 없을 경우
                            minutes.push({haveNoSchedule: true});
                        } else {
                            minutes.push({
                                cellTime, // 10분 단위 DOM 설정 시간
                                isAMOpen: list.lunchBreak // 오전 여부
                                    ? (startTime < cellTime) && (lunchStartTime >= cellTime) // 점심 시간이 있을 경우
                                    : (startTime < cellTime) && ('12:00' >= cellTime), // 없으면 12:00 로 오전, 오후 구분
                                isPMOpen: (list.lunchBreak && lunchStartTime !== '00:00' && lunchEndTime !== '00:00') // 오후 여부
                                    ? (lunchEndTime < cellTime) && endTime >= cellTime // 점심 시간이 있을 경우
                                    : ('12:00' <= cellTime) && endTime >= cellTime, // 없으면 12:00 로 오전, 오후 구분
                                isLunchTime: list.lunchBreak && lunchStartTime < cellTime && lunchEndTime >= cellTime, // 점심 여부
                                isBreakTime: list.isOffDay && (breakStartTime < cellTime && breakEndTime >= cellTime), // 휴진 여부
                                amStartTime: getTenMinutesLater(startTime), // 오전 시작 시간
                                pmStartTime: list.lunchBreak ? getTenMinutesLater(pmStartTime) : '12:10', // 오후 시작 시간
                                lunchStartTime: getTenMinutesLater(lunchStartTime), // 점심 시작 시간
                                breakStartTime: getTenMinutesLater(breakStartTime) // 휴진 시작 시간
                            });
                        }
                    }

                    tmpSchedule.push({
                        hour,
                        minutes
                    });
                }
                tmpSchedule.unshift(doctorsInfo);
                return tmpSchedule;
            });
            setDoctorsDaySchedule(scheduleList);
        }
    }, [data]);

    return (
        <Wrapper>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead>{dayjs(date).format('YYYY-MM-DD')}</TableHead>
                        {Array.from({length: 24}).map((_, index) => {
                            return (
                                <TableHead key={`${index}-date`}>
                                    {index < 10 ? `0${index}` : index}
                                </TableHead>
                            )
                        })}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {doctorsDaySchedule.map((doctor, i) => {
                        return (
                            <TableRow key={`${i}-info`}>
                                {doctor.map((doctorData, j) => {
                                    if (j === 0) { // 테이블 첫번째 column
                                        return (
                                            <TableData key={`${j}-hour`}>
                                                <TimeBox>
                                                    {doctorData.doctorsName}
                                                </TimeBox>
                                            </TableData>
                                        );
                                    }

                                    return (
                                        <TableData key={`${j}-hour`}>
                                            <TimeBox>
                                                {doctorData.minutes.map((minute, k) => {
                                                    const bgColor = scheduleBgColor(minute);

                                                    if (minute.haveNoSchedule && j === 1 && k === 0) { //  진료실은 있고, 입력된 스케줄이 없을 경우, 한번만 출력
                                                        return (
                                                            <NoScheduleText key={`${k}-no-schedule`}>
                                                                입력된 스케줄이 없습니다.
                                                            </NoScheduleText>
                                                        );
                                                    }

                                                    let isAM = !minute.isBreakTime && minute.isAMOpen && minute.amStartTime === minute.cellTime; // 오전 여부
                                                    let isLunch = !minute.isBreakTime && minute.isLunchTime && minute.lunchStartTime === minute.cellTime; // 점심 여부
                                                    let isPM = !minute.isBreakTime && minute.isPMOpen && minute.pmStartTime === minute.cellTime; // 오후 여부
                                                    let isBreak = minute.isBreakTime && minute.breakStartTime === minute.cellTime; // 휴진 여부

                                                    return (
                                                        <MinuteBox
                                                            key={`${k}-${minute.cellTime}-time`}
                                                            name={minute.cellTime}
                                                            $bgColor={bgColor}>
                                                            {
                                                                (isAM || isLunch || isPM || isBreak) && (
                                                                    <TimeTypeText
                                                                        $zIndex={
                                                                            (isLunch && 2) || // 점심
                                                                            (isPM && 3) // 오후
                                                                        }>
                                                                        {isAM ? '오전' : null}
                                                                        {isPM ? '오후' : null}
                                                                        {isLunch ? '점심' : null}
                                                                        {isBreak ? '휴진' : null}
                                                                    </TimeTypeText>
                                                                )
                                                            }
                                                        </MinuteBox>
                                                    )
                                                })}
                                            </TimeBox>
                                        </TableData>
                                    );
                                })}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </Wrapper>
    )
}

export default OperationDaySchedule;
