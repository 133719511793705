import { gql } from '@apollo/client';

export const REQUEST_USER_EMAIL_AUTH_CODE = gql`
    mutation RequestUserEmailAuthCode($email: String!) {
        requestUserEmailAuthCode(email: $email)
    }
`;

export const UPDATE_USER_EMAIL = gql`
    mutation UpdateUserEmail($authCode: String!, $email: String!) {
        updateUserEmail(authCode: $authCode, email: $email)
    }
`;

export const UPDATE_USER_PASSWORD = gql`
    mutation UpdateUserPassword($currentPassword: String, $newPassword: String) {
        updateUserPassword(
            currentPassword: $currentPassword
            newPassword: $newPassword
        )
    }
`;

export const UPDATE_USER_ALIM = gql`
    mutation UpdateUserAlim(
        $userHnAlim: Boolean
        $userPnAlim: Boolean
        $userResAlim: Boolean
        $userSpecialAlim: Boolean
    ) {
        updateUserAlim(
            user_hnAlim: $userHnAlim
            user_pnAlim: $userPnAlim
            user_resAlim: $userResAlim
            user_specialAlim: $userSpecialAlim
        )
    }
`;

// export const UPLOAD_DID_ATTACHED = gql`
//     mutation UploadDidAttached($file: Upload!) {
//         uploadDidAttached(file: $file)
//     }
// `;

export const DELETE_SEARCH_HISTORY = gql`
    mutation DeleteSearchHistory($shId: Int!) {
        deleteSearchHistory(sh_id: $shId)
    }
`;

export const CHECK_NOTI = gql`
    mutation Mutation {
        checkNoti
    }
`;

