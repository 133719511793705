import {toast} from "react-toastify";

export const changeInfoValidCheck = inputs => {
    const { name, phoneNumber, authCode } = inputs;
    let valid = false;

    if (name === '') {
        toast.error('이름을 입력해주세요.');
        valid = true;
    } else if (phoneNumber === '') {
        toast.error('휴대폰 번호를 입력해주세요.');
        valid = true;
    } else if (authCode === '') {
        toast.error('인증번호를 입력해주세요.');
        valid = true;
    }
    return valid;
}
