import React, {useContext} from "react";
import styled from "styled-components";
import {MonitorSetupContext} from "./MonitorSetupModal";
import theme from "../../../../styles/theme";
import StyledInput from "../../../styled/StyledInput";
import StyledSelect from "../../../styled/StyledSelect";
import StyledFlexBox from "../../../styled/StyledFlexBox";
import StyledFileUpload from "../../../styled/StyledFileUpload";
import StyledButton from "../../../styled/StyledButton";
import StyledCustomSelect from "../../../styled/StyledCustomSelect";
import {RegularText} from "../../../styled/StyledText";
import SortableFileList from "../SortableFileList";

const Wrapper = styled.div`
  display: flex;
`;
const Section = styled.div`
  flex: 1;
`;
const Line = styled.div`
  position: relative;
  margin: 0 40px;

  &:after {
    content: '';
    width: 1px;
    height: calc(100% + 190px);
    top: -148px;
    position: absolute;
    background-color: ${theme.colors.ultraLightGrayBorder};
  }
`;
const BlueText = styled(RegularText)`
  font-size: 12px;
  color: ${theme.colors.activeBlue};
  margin: 20px 0;
`;

const DefaultSetupForm = ({ handleSave }) => {
    const {
        options: { defaultState, screenState },
        onChange,
        onChangeFile,
        fileList,
        setFileList,
        previewList,
        setPreviewList,
        deletedFileId,
        setDeletedFileId
    } = useContext(MonitorSetupContext);

    return (
        <Wrapper>
            <Section>
                <StyledInput
                    ASTERISK
                    title='모니터 제목'
                    name='name'
                    value={defaultState.name}
                    onChange={e => onChange(e, 'defaultState')}
                    placeholder='모니터 제목을 입력해주세요. 예) 로비 메인 모니터'
                />
                <StyledFlexBox margin='20px 0' justifyContent='space-between'>
                    <StyledSelect
                        title='진료실명 표시 방법'
                        options={['진료실명 - 의사명', '의사명 - 진료실명']}
                        name='clinicDisplayMethod'
                        value={defaultState.clinicDisplayMethod}
                        onChange={e => onChange(e, 'defaultState')}
                    />
                    <StyledSelect
                        title='대기자수 표시 방법'
                        options={['00 명', '대기 : 00 명', '표시안함']}
                        name='standbyPersonDisplayMethod'
                        value={defaultState.standbyPersonDisplayMethod}
                        onChange={e => onChange(e, 'defaultState')}
                    />
                </StyledFlexBox>
                <StyledFlexBox margin='20px 0' justifyContent='space-between'>
                    <StyledSelect
                        title='응급환자 색상 구분'
                        options={['사용', '미사용']}
                        name='usingEmergencyPatient'
                        value={defaultState.usingEmergencyPatient}
                        onChange={e => onChange(e, 'defaultState')}
                    />
                    <StyledCustomSelect
                        COLOR
                        title='색상 종류'
                        options={['핑크색', '주황색', '노랑색']}
                        name='emergencyPatientColor'
                        value={defaultState.emergencyPatientColor}
                        onChange={e => onChange(e, 'defaultState')}
                    />
                </StyledFlexBox>
                <StyledFlexBox margin='20px 0' justifyContent='space-between'>
                    <StyledSelect
                        title='보류자 색상 구분'
                        options={['사용', '미사용']}
                        name='usingHolders'
                        value={defaultState.usingHolders}
                        onChange={e => onChange(e, 'defaultState')}
                    />
                    <StyledCustomSelect
                        COLOR
                        title='색상 종류'
                        options={['초록색', '연두색', '회색']}
                        name='holdersColor'
                        value={defaultState.holdersColor}
                        onChange={e => onChange(e, 'defaultState')}
                    />
                </StyledFlexBox>
                <StyledFlexBox margin='20px 0' justifyContent='space-between'>
                    <StyledSelect
                        title='대기자 글자 크기'
                        options={['크게', '중간', '작게']}
                        name='holdersFontSize'
                        value={defaultState.holdersFontSize}
                        onChange={e => onChange(e, 'defaultState')}
                    />
                    <StyledSelect
                        title='호출자 글자 크기'
                        options={['크게', '중간', '작게']}
                        name='callerFontSize'
                        value={defaultState.callerFontSize}
                        onChange={e => onChange(e, 'defaultState')}
                    />
                </StyledFlexBox>
                <StyledFlexBox margin='20px 0' justifyContent='space-between'>
                    <StyledSelect
                        title='호출 문구 노출 사용'
                        options={['사용', '미사용']}
                        name='usingCallPhrase'
                        value={defaultState.usingCallPhrase}
                        onChange={e => onChange(e, 'defaultState')}
                    />
                    <StyledSelect
                        title='호출시 음성 호출 사용'
                        options={['사용', '미사용']}
                        name='usingCallVoice'
                        value={defaultState.usingCallVoice}
                        onChange={e => onChange(e, 'defaultState')}
                    />
                </StyledFlexBox>
                <StyledButton
                    title='저장하기'
                    margin='156px 0 0'
                    onClick={handleSave}
                />
            </Section>
            <Line/>
            <Section>
                <StyledFileUpload
                    accept={screenState.mediaType === '영상' ? 'video/*' : 'image/*'}
                    onChange={onChangeFile}
                />
                <BlueText>순서대로 재생됩니다.</BlueText>
                <SortableFileList
                    type={screenState.mediaType}
                    screenState={screenState}
                    fileList={fileList}
                    setFileList={setFileList}
                    previewList={previewList}
                    setPreviewList={setPreviewList}
                    deletedFileId={deletedFileId}
                    setDeletedFileId={setDeletedFileId}
                />
            </Section>
        </Wrapper>
    )
}

export default DefaultSetupForm;
