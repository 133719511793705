import React, { createContext, useState } from 'react';
import { toast } from 'react-toastify';
import {removeCookie, setCookie} from "../utils/cookie";

export const AuthContext = createContext(null);

export const AuthProvider = ({ isLoggedIn: isLoggedInProp, children }) => {
	const [isLoggedIn, setIsLoggedIn] = useState(isLoggedInProp);

	const userLogin = async token => {
		try {
			setCookie('mediplatform_token', token);
			setIsLoggedIn(true);
			window.location.href = '/home';
		} catch (e) {
			toast.error(e.message);
		}
	};

	const userLogout = async () => {
		try {
			removeCookie('mediplatform_token');
			setIsLoggedIn(false);
			window.location.href = '/';
		} catch (e) {
			toast.error(e.message);
		} finally {
			removeCookie('mediplatform_token');
			setIsLoggedIn(false);
			window.location.href = '/';
		}
	};

	return (
		<AuthContext.Provider value={{ isLoggedIn, userLogin, userLogout }}>
			{children}
		</AuthContext.Provider>
	);
};
