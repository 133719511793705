import React, {useCallback, useContext, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {SEE_PLATFORM_NOTICE} from "../../../../graphql/Home/query";
import {NoticeContext} from "../notice";
import PageNation from '../../../../components/layout/PageNation';
import Loader from '../../../../components/share/Loader';
import NoticeList from "../../../../components/feature/Home/NoticeList";
import PlatformNoticeDetailModal from "../../../../components/feature/Home/Notice/PlatformNoticeDetailModal";
import {errorMessage} from "../../../../utils/commons";
import {UPDATE_HOSPITAL_NOTICE_USER_VIEW} from "../../../../graphql/Home/mutation";
import {TableBody} from '../notice.styles';

const PlatformNotice = () => {
    const {year} = useContext(NoticeContext);

    const [commentModal, setCommentModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const [take, setTake] = useState(10);
    const [cursor, setCursor] = useState(0);
    const [page, setPage] = useState(1);

    const {data, loading, refetch} = useQuery(SEE_PLATFORM_NOTICE, {
        variables: {
            searchTerm: '',
            year: parseInt(year, 10),
            filter: 'total',
            take: take,
            cursor: cursor
        }
    });
    const [updateHospitalNoticeUserView] = useMutation(UPDATE_HOSPITAL_NOTICE_USER_VIEW);

    const handleOpenComment = useCallback(async id => {
        setSelectedId(id);
        setCommentModal(true);

        try { // 공지사항 조회 수 count
            await updateHospitalNoticeUserView({
                variables: {
                    pnId: id
                }
            });
        } catch (e) {
            errorMessage(e.message);
        }
    }, [data]);

    const onClickPage = useCallback(page => {
        setPage(page);
        if (page === 1) {
            setCursor(0);
        } else {
            setCursor((page - 1) * take);
        }
    }, [take]);

    return (
        <>
            <TableBody>
                {loading
                    ? <Loader/>
                    : data?.seePlatformNotice?.platformNotice.map(notice => {
                        const recentlyCommentDate = notice?.pnComment[notice?.pnComment?.length - 1]?.pnc_createdAt; // 최근 댓글 생성일

                        return (
                            <NoticeList
                                DETAIL
                                key={`${notice?.pn_id}-notice-list`}
                                id={notice?.pn_id}
                                title={notice?.pn_title}
                                creator={notice?.pn_adminCreatorName}
                                creatorRank={notice?.pn_adminCreatorRank}
                                creatorImage={notice?.hn_creatorImg}
                                createDate={notice?.pn_createdAt}
                                content={notice?.pn_text}
                                commentLength={notice?.pnComment?.length}
                                recentlyCommentDate={recentlyCommentDate}
                                handleOpenComment={handleOpenComment}
                            />)
                    })}
            </TableBody>
            <PageNation
                totalLength={data?.seePlatformNotice?.totalLength}
                take={take}
                page={page}
                setTake={setTake}
                onClickPage={onClickPage}
                takeOption={[10, 20, 30]}
            />

            <PlatformNoticeDetailModal
                commentModal={commentModal}
                setCommentModal={setCommentModal}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                noticeListRefetch={refetch}
            />
        </>
    )
}

export default PlatformNotice;
