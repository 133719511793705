import {useCallback, useState} from "react";
import {toast} from "react-toastify";

export const useFileUpload = () => {
    const [fileList, setFileList] = useState([]);
    const [previewList, setPreviewList] = useState([]);

    const onChangeFile = useCallback(e => { // 파일 업로드
        const fileArray = Array.from(e.target.files);
        let tmpFileList = [...fileList];
        let tmpPreviewList = [...previewList];

        // 파일업로드 중복 체크
        const findIncludes = () => {
            const fileNameList = fileList.map(list => list.name);
            let valid = false;

            for (let i = 0; i < fileArray.length; i++) {
                valid = fileNameList.includes(fileArray[i].name);
            }
            return valid;
        };

        // 중복파일 검사
        if (findIncludes()) {
            toast.error('중복된 파일이 있습니다.');
            return;
        }

        fileArray.forEach(file => {
            let previewUrl = URL.createObjectURL(file);

            tmpPreviewList.push({
                name: file.name,
                size: file.size,
                url: previewUrl
            });
            tmpFileList.push(file);
        });

        setFileList(tmpFileList);
        setPreviewList(tmpPreviewList);
    }, [fileList, previewList]);

    return {
        fileList,
        setFileList,
        previewList,
        setPreviewList,
        onChangeFile
    }
}
