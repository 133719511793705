import { gql } from '@apollo/client';

export const UPDATE_RESERVATION_STATUS = gql`
    mutation UpdateReservationStatus($reId: Int!, $status: reStatus) {
        updateReservationStatus(re_id: $reId, status: $status)
    }
`;

export const DELETE_RESERVATION = gql`
    mutation DeleteReservation($reId: Int!) {
        deleteReservation(re_id: $reId)
    }
`;

export const CREATE_RESERVATION = gql`
    mutation CreateReservation(
        $patientName: String!
        $patientCellphone: String!
        $resDate: String!
        $time: String!
        $status: reStatus!
        $doctorRoomName: String!
        $patientId: Int
        $oneLineMemo: String
        $largeCategory: String
        $smallCategory: String
        $alimType: resAlimType
        $alimTemplateId: Int
        $alimTime1: Boolean
        $alimTime2: Boolean
        $alimTime3: Boolean
        $alimTime4: Boolean
    ) {
        createReservation(
            patientName: $patientName
            patientCellphone: $patientCellphone
            resDate: $resDate
            time: $time
            status: $status
            doctorRoomName: $doctorRoomName
            patientId: $patientId
            oneLineMemo: $oneLineMemo
            largeCategory: $largeCategory
            smallCategory: $smallCategory
            alimType: $alimType
            alimTemplateId: $alimTemplateId
            alimTime1: $alimTime1
            alimTime2: $alimTime2
            alimTime3: $alimTime3
            alimTime4: $alimTime4
        )
    }
`;

export const UPDATE_RESERVATION = gql`
    mutation UpdateReservation(
        $reId: Int!
        $resDate: String!
        $oneLineMemo: String
        $time: String!
        $status: reStatus!
        $doctorRoomName: String!
        $largeCategory: String
        $smallCategory: String
        $alimType: resAlimType
        $alimTime1: Boolean
        $alimTime2: Boolean
        $alimTime3: Boolean
        $alimTime4: Boolean
        $alimTemplateId: Int
    ) {
        updateReservation(
            re_id: $reId
            resDate: $resDate
            oneLineMemo: $oneLineMemo
            time: $time
            status: $status
            doctorRoomName: $doctorRoomName
            largeCategory: $largeCategory
            smallCategory: $smallCategory
            alimType: $alimType
            alimTime1: $alimTime1
            alimTime2: $alimTime2
            alimTime3: $alimTime3
            alimTime4: $alimTime4
            alimTemplateId: $alimTemplateId
        )
    }
`;
