import {useCallback, useContext, useState} from "react";
import {
    Wrapper,
    Image,
    WhiteSection,
    BlueSection,
    LoginForm,
    LoginTitle,
    LoginSubTitle,
    IntroMainText,
    IntroSubText,
    PlatformImage,
    LoginButton, LoaderBox, Loader, GrayText, Line, SignUp
} from "./login.styles";
import StyledInput from "../../components/styled/StyledInput";
import StyledPasswordInput from "../../components/styled/StyledPasswordInput";
import logo from '../../assets/images/logo.png';
import platformImage from '../../assets/images/platformImage.png';
import {toast} from "react-toastify";
import {AuthContext} from "../../context/AuthContext";
import {isEmail} from "../../utils/commons";
import StyledFlexBox from "../../components/styled/StyledFlexBox";
import {useMutation} from "@apollo/client";
import {USER_LOGIN} from "../../graphql/Login/mutation";
import LogoutLogo from "../../components/share/LogoutLogo";
import {useNavigate} from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();
    const {userLogin} = useContext(AuthContext);
    const [inputs, setInputs] = useState({
        email: '',
        password: '',
    });
    const [loading, setLoading] = useState(false);
    const {email, password} = inputs;

    const [adminLogin] = useMutation(USER_LOGIN);

    const onChangeInput = useCallback(e => {
        const {name, value} = e.target;

        setInputs({
            ...inputs,
            [name]: value
        });
    }, [inputs]);

    const handleSubmit = useCallback(async e => {
        e.preventDefault();

        if (email === '') {
            toast.error('이메일을 입력해주세요.');
            return;
        }
        if (password === '') {
            toast.error('비밀번호를 입력해주세요.');
            return;
        }
        if (!isEmail(email)) {
            toast.error('이메일 형식이 아닙니다.');
            return;
        }

        setLoading(true);
        try {
            const { data } = await adminLogin({
                variables: {
                    accountId: email,
                    password: password
                }
            });

            if (data.userLogin) {
                userLogin(data.userLogin);
                setLoading(false);
            }
        } catch (e) {
            toast.error(e.message);
        } finally {
            setLoading(false);
        }
    }, [email, password]);

    return (
        <Wrapper>
            <LogoutLogo />
            <WhiteSection>
                <LoginForm method="post" onSubmit={handleSubmit}>
                    <LoginTitle>로그인</LoginTitle>
                    <LoginSubTitle>메디:플랫폼 회원 전용 로그인입니다.</LoginSubTitle>
                    <StyledInput
                        width={340}
                        name='email'
                        value={email}
                        onChange={onChangeInput}
                        placeholder='이메일'
                    />
                    <StyledPasswordInput
                        width={340}
                        margin='12px 0 28px'
                        name='password'
                        value={password}
                        onChange={onChangeInput}
                        placeholder='비밀번호'
                    />
                    <LoginButton onClick={handleSubmit}>
                        {loading
                            ? (
                                <LoaderBox>
                                    <Loader />
                                </LoaderBox>
                            )
                            : '로그인'
                        }
                    </LoginButton>
                    <StyledFlexBox alignItems='center' justifyContent='flex-end'>
                        <GrayText onClick={() => navigate('/find/email')}>이메일 찾기</GrayText>
                        <Line />
                        <GrayText onClick={() => navigate('/find/password')}>비밀번호 찾기</GrayText>
                    </StyledFlexBox>
                </LoginForm>
            </WhiteSection>
            <BlueSection>
                <IntroMainText>
                    병·의원 운영에 관한 모든 것, <br/>
                    메디:플랫폼 지금 바로 시작하세요.
                </IntroMainText>
                <IntroSubText>
                    최신 IT기술을 접목하여 사용자들의 편의성과 효율을<br/>
                    극대화한 솔루션으로 진료관리, 병원경영, 고객관리 등<br/>
                    의료행위에 집중할 수 있도록 설계된 웹 플랫폼입니다.
                </IntroSubText>
                <PlatformImage>
                    <Image src={platformImage}/>
                </PlatformImage>
            </BlueSection>
        </Wrapper>
    )
}

export default Login;
