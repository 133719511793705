import React, {useEffect} from 'react';
import {Routes, Route, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {AuthProvider} from './context/AuthContext';
import ErrorPage from './pages/Error';
import SideBar from "./components/layout/SideBar";
import PlatformHeader from "./components/layout/PlatformHeader";
import Login from './pages/Login';
import Home from './pages/Home/Main';
import Notice from './pages/Home/Notice';
import DID from './pages/DID';
import HospitalNotice from "./pages/Home/Notice/HospitalNotice";
import PlatformNotice from "./pages/Home/Notice/PlatformNotice";
import Appointment from "./pages/Appointment";
import Schedule from "./pages/Schedule";
import OperationSchedule from "./pages/Schedule/Operation";
import SpecialSchedule from "./pages/Schedule/Special";
import Patient from "./pages/Patient";
import Insurance from "./pages/Insurance";
import Service from "./pages/ServiceCenter";
import ServiceCenter from "./pages/ServiceCenter/ServiceCenter";
import QNA from "./pages/ServiceCenter/QNA";
import ServiceNotice from "./pages/ServiceCenter/Notice";
import Profile from "./pages/Profile";
import Setting from "./pages/Setting";
import DayOff from "./pages/HospitalDayOff";
import Manager from "./pages/Manager";
import FindEmail from "./pages/FindInfo/FindEmail";
import FindPassword from "./pages/FindInfo/FindPassword";

const Wrapper = styled.div`
  // scroll bar width 
  min-width: calc(1920px - 10px);
  width: calc(100vw - 10px);
  max-width: calc(2160px - 10px);
  
  min-height: 100vh;
  display: flex;
`;
const ContentsSection = styled.section`
  width: calc(100% - 76px);
  min-height: 100vh;
  height: 100%;
`;

const LoggedOutRoutes = () => (
    <Routes>
        <Route path="/" element={<Login/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/find/email" element={<FindEmail/>}/>
        <Route path="/find/password" element={<FindPassword/>}/>
        <Route path="*" element={<ErrorPage/>}/>
    </Routes>
);

const LoggedInRoutes = () => (
    <Routes>
        <Route path="/home" element={<Home/>}>

        </Route>
        <Route path="/home/notice" element={<Notice/>}>
            <Route path="hospital" element={<HospitalNotice/>}/>
            <Route path="platform" element={<PlatformNotice/>}/>
        </Route>
        <Route path="/appointment" element={<Appointment/>}/>
        <Route path="/schedule" element={<Schedule/>}>
            <Route path='operation' element={<OperationSchedule/>}/>
            <Route path='special' element={<SpecialSchedule/>}/>
        </Route>
        <Route path="/patient" element={<Patient/>}/>
        <Route path="/did" element={<DID/>}/>
        <Route path="/insurance" element={<Insurance/>}/>
        <Route path="/service" element={<Service/>}>
            <Route path='center' element={<ServiceCenter/>}/>
            <Route path='qna' element={<QNA/>}/>
            <Route path='notice' element={<ServiceNotice/>}/>
        </Route>
        <Route path="/profile" element={<Profile/>}/>
        <Route path="/setting" element={<Setting/>}/>
        <Route path="/setting/day-off" element={<DayOff/>}/>
        <Route path="/setting/manager" element={<Manager/>}/>

        <Route path="*" element={<ErrorPage/>}/>
    </Routes>
);

export const Router = ({isLoggedIn}) => {
    const { pathname } = useLocation();

    useEffect(() => {
        const section = document.getElementById('contentsSection');
        section?.scrollTo({ top: 0 });
    }, [pathname]);

    if (!isLoggedIn)
        return (
            <AuthProvider isLoggedIn={isLoggedIn}>
                <LoggedOutRoutes/>
            </AuthProvider>
        );

    return (
        <AuthProvider isLoggedIn={isLoggedIn}>
            <Wrapper>
                <SideBar/>
                <ContentsSection id="contentsSection">
                    <PlatformHeader/>
                    <LoggedInRoutes/>
                </ContentsSection>
            </Wrapper>
        </AuthProvider>
    );
};
